import React from "react"
import Navbar2 from "../components/Navbar2"
import HelpPage from "../components/HelpPage"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import "../static/style.css"

const Help = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Navbar2 />
      <HelpPage />
    </I18nextProvider>
  )
}

export default Help
