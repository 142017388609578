import React from "react"
import { Jumbotron, Card } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Print from "react-ionicons/lib/IosPrintOutline"
import Internet from "react-ionicons/lib/IosWifiOutline"
import Settings from "react-ionicons/lib/IosSettingsOutline"
import Mail from "react-ionicons/lib/IosMailOutline"
import { Link as ReachLink } from "@reach/router"
import Img from "gatsby-image"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import { useTranslation } from "react-i18next"

const Help = () => {
  const data = useStaticQuery(graphql`
    query {
      queue: file(relativePath: { eq: "QUEUEPos.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const cardStyles = {
    width: "18rem",
    margin: 20,
    borderRadius: 30,
    justifyContent: "center",
    height: "18rem",
  }
  const { t } = useTranslation()
  return (
    <Jumbotron>
      <div className="container">
        <div className="row" style={{ justifyContent: "center", margin: 20 }}>
          <h1 style={{ paddingTop: 30 }}>Queue Product Assistance</h1>
        </div>
      </div>
      <div className="row" style={{ justifyContent: "center" }}>
        <ReachLink to="print">
          <Card style={cardStyles}>
            <Card.Body>
              <Print style={{ height: "40%" }} />
              <Card.Title>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <h3>Printing Assistance</h3>
                </div>
              </Card.Title>
            </Card.Body>
          </Card>
        </ReachLink>
        <ReachLink to="internet">
          <Card style={cardStyles}>
            <Card.Body>
              <Internet style={{ height: "40%" }} />
              <Card.Title>
                <div style={{ flexDirection: "row", justifyContent: "center" }}>
                  <h3>Internet Troubleshooting</h3>
                </div>
              </Card.Title>
            </Card.Body>
          </Card>
        </ReachLink>
        <ReachLink to="settings">
          <Card style={cardStyles}>
            <Card.Body>
              <Settings style={{ height: "40%" }} />
              <Card.Title>
                <div style={{ flexDirection: "row", justifyContent: "center" }}>
                  <h3>Application Special Settings</h3>
                </div>
              </Card.Title>
            </Card.Body>
          </Card>
        </ReachLink>
      </div>
    </Jumbotron>
  )
}

export default Help
